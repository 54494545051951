import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Layout from '../components/Layout';
import { graphql } from 'gatsby';
import SEO from '../components/seo';

const useStyles = makeStyles((theme) => ({
  container: {
    margin: 'auto',
    maxWidth: '650px',
    padding: '1.5em',
    paddingTop: '2em',
  },
}));

export default function Story({ data }) {
  const classes = useStyles();

  return (
    <Layout pageTitle="Pilvikoneen tarina">
      <SEO title="Read the story of Pilvikone" />
      <div className={classes.container}>
        <article dangerouslySetInnerHTML={{ __html: data.story.text }} />
      </div>
      <div
        style={{
          position: 'absolute',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          backgroundColor: 'white',
          opacity: 0.8,
          zIndex: -1,
        }}
      />
    </Layout>
  );
}

export const query = graphql`
  query {
    story {
      text
    }
  }
`;
